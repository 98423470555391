.gifts {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	padding: 15px 20px;
	background: var(--secondary-color);
	border-radius: 24px 24px 0 0;

	@media (min-width: 768px) {
		padding: 30px 40px;
		flex-direction: initial;
	}
}

.giftItem {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	gap: 10px;

	img {
		width: 36px;
		height: 36px;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.text {
	font-size: 13px;
	line-height: 148%;
	color: var(--color-white);
	@media (min-width: 768px) {
		font-size: 14px;
	}
}

.anotherGift {
	padding: 7px 30px;
	margin-top: 0;
	width: 100%;

	letter-spacing: 0;
	font-size: 13px;
	line-height: 19px;
	text-align: center;

	background: var(--primary-color);
	color: #ffffff;

	cursor: pointer;

	span {
		text-decoration: underline;
	}
}

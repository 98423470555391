@import "./fonts.css";

:root {
	--color-black: #262626;
	--color-white: #f9f9f9;
	--color-grey: #bacad2;
	--color-yelow: #ffb500;
	--color-text: #484848;

	//chat
	--primary-color: #5cacb1;
	--secondary-color: #1e1e21;
	--image-size-m: 36px;
	--image-size-d: 60px;
	--gap: 24px;
	--margin-left-m: calc(var(--image-size-m) + var(--gap));
	--margin-left-d: calc(var(--image-size-d) + var(--gap));
	--chat-font-family: "RobotoCondensed", sans-serif, Helvetica;
	--chat-page-background: #d6e1e7;
	--benefit-color: #828282;
	--chat-title-color: #d6e1e7;
	--bot-message: #ff7900;
}

html,
body {
	padding: 0;
	margin: 0;
}

body {
	font-family: "Manrope-Medium";
}

* {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
	transition: color 0.3s ease;

	@media (min-width: 1024px) {
		&:hover {
			color: white;
		}
	}
}

* {
	box-sizing: border-box;
}

p,
ol,
ul {
	font-size: 16px;
	line-height: 150%;
	margin: 0.75em 0;
}

ol li {
	margin-bottom: 0.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "TacticSansExd", sans-serif, Helvetica;
}

h2 {
	font-size: 20px;
	margin: 0 0 0.75em;
}

h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	margin: 1.25em 0 0;
}

h4 {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
}

h4[color="white"] {
	color: white;
}

b {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 140%;
}

b[color="white"] {
	color: white;
}

h2[color="white"] {
	color: white;
}

.App {
	min-width: 340px;
}

.accent {
	color: #002f87;
}

@media screen and (min-width: 1200px) {
	.md {
		display: none;
	}

	.sm {
		display: none;
	}

	.hd {
		display: block;
	}

	h1 {
		font-size: 60px;
		line-height: 80px;
		margin: 0 0 1em;
	}

	h2 {
		font-weight: 500;
		font-size: 58px;
		line-height: 120%;
	}

	h3 {
		font-size: 28px;
		margin-top: 1em;
	}

	p,
	ol,
	ul {
		font-size: 16px;
		line-height: 150%;
	}

	ol,
	ul {
		padding-left: 32px;
	}
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

.container {
	max-width: 100%;
	width: 100%;
	margin: auto;
	padding: 0 16px;
}

@media screen and (min-width: 996px) {
	.container {
		max-width: 1300px;
		margin: auto;
		padding: 0 20px;
	}
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
	.hd {
		display: none;
	}

	.sm {
		display: none;
	}

	.md {
		display: block;
	}
}

@media screen and (max-width: 992px) {
	.md {
		display: none;
	}

	.hd {
		display: none;
	}

	.sm {
		display: block;
	}

	h1 {
		font-size: 32px;
		font-weight: 700;
		line-height: 120%;
	}

	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 130%;
	}
}

input:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0 50px #fff !important;
	-webkit-text-fill-color: #33333d !important;
}

.callback {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	pointer-events: none;
	z-index: 900;
	opacity: 0;
	color: #33333d;
	display: flex;
}

.callback *:not(input) {
	user-select: none;
}

.callback.active {
	opacity: 1;
	background-color: rgba(0, 0, 17, 0.2);
	pointer-events: auto;
}

.callback.active form {
	opacity: 1;
}

.callback__form {
	width: 100%;
	position: relative;
	margin: auto;
	padding: 38px 34px 28px;
	box-shadow: 20px 27px 70px 0 rgba(0, 0, 0, 0.1);
	opacity: 0;
}

.callback__comment {
	color: #555559;
	opacity: 0.8;
	margin: 0 0 24px;
}

.callback__label {
	font-size: 12px;
	color: #777;
}

.callback__input {
	display: block;
	width: 100%;
	margin: 4px 0 12px;
	padding: 0 16px;
	line-height: 47px;
	border: 1px solid #e6e6e6;
	font-size: 16px;
	transition: border-color 0.17s ease;
}

.callback__input:focus {
	border-color: #1ca5b8;
}

.callback__input.err {
	border-color: #f0433a;
}

.callback__input.success {
	border-color: #39b54a;
}

.callback__message {
	margin: 4px 0 20px;
	position: relative;
	height: 0;
	transition: border-color 0.17s ease;
}

.callback__message:after {
	position: absolute;
	right: 0;
	text-align: right;
	content: attr(d-t);
	height: 1em;
	font-size: 10px;
	display: block;
	color: #f0433a;
	top: 10px;
	transition: top 0.17s ease;
	opacity: 0;
}

.callback__load {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.84);
	z-index: 1;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.3s ease, background 0.3s ease;
}

.callback__load .cb-load-text {
	padding: 80px 40px;
}

.callback__load:before {
	position: relative;
	content: "";
	top: 50%;
	left: 50%;
	width: 7em;
	height: 7em;
	display: block;
	margin: -3.5em 0 0 -3.5em;
	border-radius: 50%;
	border-top: 2px solid #232323;
	animation: cb_load 1.4s linear 1.4s normal infinite;
	transition: opacity 0.3s ease;
}

.callback__close {
	width: 2em;
	height: 2em;
	padding: 19px;
	display: block;
	background-size: 50%;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	right: 0;
	top: 0;
	cursor: pointer;
	opacity: 0.7;
	z-index: 11;
	background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ljk3MSA0Ny45NzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ3Ljk3MSA0Ny45NzE7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMjguMjI4LDIzLjk4Nkw0Ny4wOTIsNS4xMjJjMS4xNzItMS4xNzEsMS4xNzItMy4wNzEsMC00LjI0MmMtMS4xNzItMS4xNzItMy4wNy0xLjE3Mi00LjI0MiwwTDIzLjk4NiwxOS43NDRMNS4xMjEsMC44OCAgIGMtMS4xNzItMS4xNzItMy4wNy0xLjE3Mi00LjI0MiwwYy0xLjE3MiwxLjE3MS0xLjE3MiwzLjA3MSwwLDQuMjQybDE4Ljg2NSwxOC44NjRMMC44NzksNDIuODVjLTEuMTcyLDEuMTcxLTEuMTcyLDMuMDcxLDAsNC4yNDIgICBDMS40NjUsNDcuNjc3LDIuMjMzLDQ3Ljk3LDMsNDcuOTdzMS41MzUtMC4yOTMsMi4xMjEtMC44NzlsMTguODY1LTE4Ljg2NEw0Mi44NSw0Ny4wOTFjMC41ODYsMC41ODYsMS4zNTQsMC44NzksMi4xMjEsMC44NzkgICBzMS41MzUtMC4yOTMsMi4xMjEtMC44NzljMS4xNzItMS4xNzEsMS4xNzItMy4wNzEsMC00LjI0MkwyOC4yMjgsMjMuOTg2eiIgZmlsbD0iIzJkMmQzMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}

.callback__close:hover {
	transform: scale(0.9);
	opacity: 0.5;
}

.callback.loading .callback__load {
	opacity: 1;
}

.callback__load {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0;
	transition: opacity 0.3s ease, background 0.3s ease;
}

.callback__load .cb-load-text {
	opacity: 0;
	transition: 0.3s ease;
}

.callback.sended {
	pointer-events: none;
}

.callback.sended .callback__close {
	pointer-events: auto;
}

.callback.sended .callback__success {
	opacity: 1;
}

.callback.sended .callback__load {
	opacity: 1;
	cursor: default;
	background: #ffffff;
}

.callback.sended .callback__load .cb-load-text {
	opacity: 1;
}

.callback.sended .callback__load:before {
	opacity: 0;
}

.callback.sended .callback__form {
	animation: cb_flip 1.4s linear 1.4s normal;
}

.callback .req-form__message.success:before {
	top: -42px;
}

@media only screen and (max-width: 400px) {
	.callback__form {
		max-height: 100%;
		max-width: 100%;
		height: 100%;
		padding-top: 60px;
	}

	.callback__close {
		background-size: 70%;
		top: 10px;
		right: 10px;
	}
}

.splide__pagination {
	padding: 0;
	width: 100%;
	gap: 4px;
	position: absolute;
	bottom: 25px;
	z-index: 1001;
}

.splide__pagination__page {
	width: 150px;
	height: 2px;
	flex-grow: 0;
	padding: 0;
	background-color: #838383 !important;
	border-radius: 4px;
	opacity: 1;
	border: none;
	cursor: pointer;

	@media (max-width: 1280px) {
		width: 100px;
	}
}

.splide__pagination__page.is-active {
	opacity: 1;
	background: linear-gradient(to right, #838383 0%, #ffffff 100%);
	background-size: 200% 100%;
	transition: background-position 6s;
	background-position: -100% 0;
}

.splide__arrows {
	position: absolute;
	z-index: 1000;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	height: 55%;
	padding: 0 80px;

	button {
		width: 54px;
		height: 54px;
		padding: 11px;
		display: flex;
		border-radius: 6px;
		background: #00000033;
		border: none;

		svg {
			height: 24px;
			width: 20px;
			margin: auto;

			path {
				fill: #ffffff;
			}
		}
	}
}

.splide__arrow--prev {
	rotate: 180deg;
}

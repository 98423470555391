.root {
	padding: 20px;

	@media (min-width: 768px) {
		padding: 40px;
	}
}

.title {
	margin-top: 40px;
	font-family: "Fira Sans", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.subTitle {
	font-family: "Fira Sans", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 20px;
	text-align: center;
}

.description {
	max-width: 1100px;
	margin-top: 16px;
	margin-bottom: 40px;
	font-family: "Fira Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	p ul {
		margin-top: 10px;
		list-style-type: circle;
		margin-left: 40px;

		li {
			font-family: "Fira Sans", sans-serif;
			font-weight: 400;
			font-style: normal;
			font-size: 20px;
		}
	}
}

@media screen and (min-width: 1401px) {
	.title {
		font-size: 48px;
		line-height: 130%;
	}

	.description {
		font-size: 16px;
		line-height: 145%;
		ul {
			li {
				font-size: 16px;
			}
		}
	}
	.subTitle {
	}
}

@media screen and (min-width: 781px) and (max-width: 1400px) {
	.title {
		font-size: 48px;
		line-height: 130%;
	}

	.description {
		font-size: 16px;
		line-height: 145%;
	}
}

@media screen and (max-width: 780px) {
	.title {
		font-size: 32px;
		line-height: 120%;
	}

	.description {
		font-size: 14px;
		line-height: 140%;
		ul {
			margin-left: 20px;
			li {
				font-size: 14px;
			}
		}
	}

	.subTitle {
		font-size: 18px;
	}
}

.root {
	display: flex;
	flex-direction: column;
	max-width: 992px;

	@media (min-width: 992px) {
		margin: 0 auto;
	}
}

.info {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	width: 100%;
	margin: 0;
	text-align: center;
	font-family: var(--chat-font-family);
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
	color: var(--chat-title-color);

	@media (min-width: 992px) {
		max-width: 667px;
		font-size: 42px;
		line-height: 148%;
		text-align: center;
		letter-spacing: 0.02em;
	}
}

.subtitle {
	max-width: 350px;
	font-size: 16px;
	line-height: 140%;
	font-weight: 700;
	text-align: center;
	color: var(--chat-title-color);
	@media (min-width: 992px) {
		max-width: none;
		margin-bottom: 22px;
		font-weight: 400;
		font-size: 24px;
		line-height: 26px;
	}
}

.list {
	display: flex;
	flex-direction: column;

	width: 100%;
	margin: 0;
	padding: 0;
	gap: 4px;

	list-style-type: none;

	@media (min-width: 992px) {
		flex-direction: row;
		justify-content: space-between;

		li {
			width: calc(100% / 3);
		}

		gap: 32px;
		margin-top: 12px;
	}
}

.item {
	display: flex;
	align-items: center;
	padding: 0;
	gap: 10px;

	background-color: transparent;
	border: none;

	text-align: center;
	font-size: 16px;
	line-height: 24px;
	cursor: pointer;

	@media (min-width: 992px) {
		font-size: 24px;
		line-height: 32px;
		align-items: flex-start;
		text-align: start;

		svg {
			width: 36px;
			height: 36px;
		}

		span {
			transition: color linear 0.3s;
		}

		&:hover {
			span {
				color: var(--middle-peach);
			}
		}
	}

	span {
		color: white;
	}
}

.root {
	display: inline-block;
	padding: 16px;
	margin-left: var(--margin-left-m);
	max-width: 560px;
	font-size: 14px;
	line-height: 1.5;

	align-self: flex-start;
	margin-right: 20px;
	border-radius: 2px 15px 15px 15px;
	box-shadow: 0 3px 10px rgba(38, 36, 60, 0.07);
	background-color: var(--color-white);

	@media (min-width: 992px) {
		font-size: 16px;
		padding: 16px 20px;
		margin-right: 0;
		margin-left: var(--margin-left-d);
	}
}

.first {
	margin-left: 0;
}

.user {
	align-self: flex-end;
	border-radius: 20px 20px 0 20px;
	background: var(--primary-color);
	color: #ffffff;
	margin-right: 0;
}

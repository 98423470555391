.button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	width: 100%;
	font-family: "Neo Sans Cyr", sans-serif, Helvetica;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	transition: background-color 0.3s, color 0.3s, transform 0.3s;
	cursor: pointer;
	border: none;
	text-transform: uppercase;
	@media (min-width: 992px) {
		padding: 20px 40px;
		font-size: 18px;
	}
}

.icon {
	margin-left: 16px;
}

.small {
	font-size: 15px;
	line-height: 20px;
}

.large {
	padding: 16px 20px;
	font-size: 21px;
	line-height: 26px;
}

.primary {
	color: var(--txt-black);
	background: #ffffff;
	border: 1px solid transparent;
}

.primary:hover {
	color: var(--white-peach);
	background: var(--txt-black);
	border-color: #ffffff;
}

.secondary {
	color: #ffffff;
	background: var(--txt-black);
	border: 1px solid transparent;
}

.secondary:hover {
	color: var(--txt-black);
	background: #ffffff;
	border-color: var(--txt-black);
}

.secondary:disabled,
.primary:disabled {
	color: #ffffff;
	border-color: #c0c0c0;
	background-color: #c0c0c0;
	cursor: default;
}

.invert {
	color: #ffffff;
	background: none;
	border: 1px solid #ffffff;
}

.invert:hover {
	color: #363e40;
	background: #ffffff;
	border: 1px solid rgba(0, 47, 135, 0.8);
}

.invert:disabled {
	color: #ffffff;
	border-color: #c0c0c0;
	background-color: #c0c0c0;
	cursor: default;
}

.chat {
	font-family: var(--chat-font-family);
	color: #ffffff;
	background: var(--secondary-color);
	padding: 17px 50px;
	margin: 0;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0.3px;
	text-transform: none;
	width: auto;
	font-size: 17px;
	border-radius: 30px;
	@media (min-width: 1024px) {
		&:hover {
			background: var(--primary-color);
		}
	}
}

.bot {
	width: auto;
	flex-grow: 0;
	padding: 8px 15px;
	border-radius: 6px;
	font-family: var(--chat-font-family);
	font-size: 13px;
	line-height: 14px;
	text-transform: none;
	background: var(--primary-color);
	color: #ffffff;
	letter-spacing: 0.3px;
	@media (min-width: 992px) {
		padding: 10px 15px;
	}
}

.link {
	padding: 0;
	background-color: transparent;
	border: none;
	width: max-content;

	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--color-white);
	text-decoration: underline;
	text-transform: none;

	@media (min-width: 992px) {
		padding: 0 5px;
		font-size: 24px;
		line-height: 32px;
		transition: transform 0.2s linear;

		&:hover {
			transform: scale(1.05);
		}
	}
}

@media (min-width: 520px) {
	.button {
		width: auto;
	}
}

.root {
	display: flex;
	align-items: center;
	max-width: 200px;
	gap: 10px;
	text-align: center;
	transition: all 200ms ease;
	cursor: pointer;
	user-select: none;
	background: #ffffff;
	box-shadow: 0 1px 8px rgba(38, 36, 60, 0.05);
	border-radius: 50px;
	color: #333333;
	margin-bottom: 10px;
	padding: 8px 15px 10px;
	font-size: 14px;
	line-height: 25px;
	@media (min-width: 992px) {
		padding: 12px 20px 13px;
		font-size: 15px;

		&:hover {
			background: var(--primary-color);
			color: #fff;
		}

	}
}

.color {
	width: 24px;
	height: 24px;
	border-radius: 5px;
}

.active {
	background: var(--primary-color);
	color: #fff;
}

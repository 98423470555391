@font-face {
	font-family: 'Manrope-ExtraBold';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/Manrope-ExtraBold.ttf');
	src: local('Manrope-ExtraBold'),
		url('../fonts/Manrope-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Manrope-Bold';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/Manrope-Bold.ttf');
	src: local('Manrope-Bold'),
		url('../fonts/Manrope-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Manrope-ExtraLight';
	font-style: normal;
	font-weight: 200;
	src: url('../fonts/Manrope-ExtraLight.ttf');
	src: local('Manrope-ExtraLight'),
		url('../fonts/Manrope-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'Manrope-Light';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/Manrope-Light.ttf');
	src: local('Manrope-Light'),
		url('../fonts/Manrope-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Manrope-Medium';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/Manrope-Medium.ttf');
	src: local('Manrope-Medium'),
		url('../fonts/Manrope-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Manrope-Regular';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/Manrope-Regular.ttf');
	src: local('Manrope-Regular'),
		url('../fonts/Manrope-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Manrope-SemiBold';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/Manrope-SemiBold.ttf');
	src: local('Manrope-SemiBold'),
		url('../fonts/Manrope-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Russo One';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/RussoOne.ttf');
	src: local('RussoOne'),
		url('../fonts/RussoOne.ttf') format('truetype');
}

.form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-left: var(--margin-left-m);
	max-width: 560px;

	@media (min-width: 992px) {
		margin-left: var(--margin-left-d);
	}
}

.message {
	padding: 16px;

	font-size: 16px;
	line-height: 24px;

	border: 1px solid var(--color-grey);
	border-radius: 0 20px 20px 20px;
	background-color: var(--color-white);
}

.input {
	padding: 17px 20px;
	color: #333;
	font-size: 16px;
	line-height: 1;
	max-width: 300px;
	transition: all 200ms ease;
	letter-spacing: 0.3px;
	margin: 0;
	border-radius: 34px;
	border: 1px solid #c7c7c7;
}

.policy {
	font-size: 14px;
	max-width: 300px;
	color: var(--benefit-color);
}

.policyLink {
	text-decoration: underline;
}

.button {
	font-family: var(--chat-font-family);
	padding: 15px 30px;
	border-radius: 48px;
	border: none;

	color: var(--color-white);
	font-size: 17px;
	line-height: 24px;

	background: var(--secondary-color);
	transition: background-color 0.2s linear;

	width: 100%;
	max-width: 300px;
	cursor: pointer;

	@media (min-width: 1024px) {
		&:hover {
			background-color: var(--primary-color);
		}
	}
}

.root {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;

	@media (min-width: 552px) {
		justify-content: center;
		flex-direction: row;
	}

	@media (min-width: 992px) {
		justify-content: flex-start;
	}
}

.image {
	flex-shrink: 0;
	width: 100%;
	max-width: 214px;
}

.divider {
	display: none;
	width: 1px;
	height: 42px;
	margin-left: 15px;
	margin-right: 15px;
	background-color: #cdd0d2;
	@media (min-width: 552px) {
		display: block;
		margin-left: 20px;
		margin-right: 20px;
	}
}

.text {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #767c82;
	text-align: center;

	@media (max-width: 552px) {
		display: none;
	}

	@media (min-width: 992px) {
		text-align: left;
	}
}

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 40%;
	left: 10%;
	width: 80%;
	max-width: 570px;
	background-color: white;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0px 40px 80px rgb(38 36 60 / 37%);
	z-index: 100;

	@media (min-width: 600px) {
		left: calc(50% - 570px / 2);
		padding: 20px;
	}
}

.titleWrapper {
	margin-bottom: 10px;
	text-align: center;

	font-size: 20px;
	line-height: 25px;
}

.title {
	border-bottom: 1px solid var(--primary-color);
}

.text {
	width: 100%;
	padding: 0 10px;
	letter-spacing: 0.3px;
	font-size: 13px;
}

.close {
	position: absolute;
	top: -28px;
	right: -15px;
	background-color: rgba(0, 0, 0, 0.4);
	width: 27px;
	height: 27px;
	border: none;
	border-radius: 30px;
	background-image: url("../../../../../../public/images/chat/close.svg");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 99;
}

.complectations {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
}

.complectation {
	display: flex;
	align-items: center;
	gap: 20px;

	img {
		width: 30px;
		height: 30px;
	}

	p {
		margin: 0;
	}
}

.card {
	position: relative;
	width: 100%;
	margin-right: 20px;
	@media (min-width: 992px) {
		width: auto;
		margin-right: 0;
	}
}

.wrapper {
	width: 100%;
	padding: 20px;
	background-color: var(--color-white);
	overflow: hidden;
	border-radius: 16px;
	color: var(--color-black);
	cursor: pointer;
	box-shadow: 0 4px 10px rgba(38, 36, 60, 0.08);
	border: 2px solid rgba(255, 255, 255, 0);
	@media (min-width: 700px) {
		width: 280px;
	}

	@media (min-width: 992px) {
		transition: border-color 0.2s linear, transform 0.2s linear;

		&:hover {
			border-color: var(--primary-color);
			transform: scale(1.05);
		}
	}
}

.active {
	border-color: var(--primary-color);
}

.content {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.title {
	color: #343434;
	font-size: 20px;
	line-height: 132%;
	margin-bottom: 2px;
	font-weight: bold;
}

.price,
.benefit {
	color: var(--benefit-color);
	margin: 0;
	font-size: 14px;
	line-height: 22px;
}

.image {
	width: 100%;
	object-fit: contain;
	height: 80px;
	@media (min-width: 992px) {
		height: 90px;
	}
}

.icon {
	position: absolute;
	top: -12px;
	right: -12px;
	width: 28px;
	height: 28px;

	svg {
		width: 100%;
		height: 100%;
	}
}

.wrapper {
	display: flex;
	align-items: center;
	gap: 10px;

	width: 100%;
	perspective: 500px;
	margin-right: 12px;

	@media (min-width: 992px) {
		gap: 60px;
		white-space: pre;
	}
}

.radio {
	font-family: var(--chat-font-family);
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 0;
	width: 100%;
	background-color: transparent;
	border: none;
	color: #333333;
	cursor: pointer;

	svg {
		width: 18px;
		height: 18px;
	}

	@media (min-width: 992px) {
		transition: transform 0.2s linear;
	}
}

.text {
	margin: 0;
	text-align: left;
	width: 80%;
	font-size: 13px;
	@media (min-width: 992px) {
		font-size: 15px;
		line-height: 18px;
	}
}

.icon {
	background-color: transparent;
	border: none;
	color: var(--color-black);
	cursor: pointer;
	transition: transform 0.2s linear;

	@media (min-width: 992px) {
		&:hover {
			transform: scale(1.5);
		}
	}

	&Active {
		transform: rotateZ(180deg);

		@media (min-width: 992px) {
			&:hover {
				transform: scale(1.5) rotateZ(180deg);
			}
		}
	}
}

.characteristics {
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding-left: 15px;
	padding-top: 15px;

	.characteristic {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;

		font-size: 12px;
		line-height: 16px;

		&Name {
			color: var(--dark-grey);
		}

		&Value {
			min-width: 50px;
		}
	}
}

.button {
	height: max-content;
	padding: 8px 15px;

	background-color: var(--secondary-color);
	color: var(--color-white);
	border-radius: 6px;
	border: none;
	cursor: pointer;
	transition: background-color 0.3s linear;

	@media (min-width: 1024px) {
		&:hover {
			background-color: var(--primary-color);
		}
	}
}

.root{
	padding: 0 20px;
}
.footerWrap {
	position: initial;
	padding: 20px 0;
	font-size: 10px;
	color: #FFFFFF;

	@media (min-width: 1500px) {
		position: fixed;
		left: 20px;
		bottom: 0;
		max-width: 240px;
	}

	p {
		margin: 0;
	}
}


.link {
	margin-top: 16px;
	font-size: 16px;
	height: max-content;
	text-decoration: underline;
	color: var(--dark-grey);

	@media (min-width: 992px) {
		margin-top: 0px;
	}
}
